<template>
  <LiefengContent>
    <template v-slot:title
      >党员活动情况统计</template
    >
    <template v-slot:toolsbarRight>
        <Form :label-colon="true">
          <FormItem
            :label="item.label"
            v-for="(item, index) in partySelectList"
            :key="index"
          >
            <Input :maxlength="20" v-model.trim="item.value" v-if="item.type == 'text'" />
            <Select v-model="item.value" v-else clearable>
              <Option
                :value="subItem.value"
                v-for="(subItem, subIndex) in item.list"
                :key="subIndex"
                >{{ subItem.name }}</Option
              >
            </Select>
          </FormItem>
          <Button type="primary" @click="search">查询</Button>
        </Form>
         <Button
        @click="exportExcel"
        type="error"
        icon="ios-cloud-download-outline"
        >导出</Button
      >
        <Button type="success" @click="$router.push('/partyindex')">返回</Button
      >
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="curPage"
        @hadlePageSize="pageChange"
        :loading="loading"
        :fixTable="true"
        :pagesizeOpts="[20, 30, 50, 100]"
        :pageSize="pageSize"
      ></LiefengTable>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/partystatistic")
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      loading: false,
      // 表格数据
      tableData: [],
      //表头数据
      talbeColumns: [
        {
          type: "selection",
          width: 80,
          align: "center",
        },
        {
          title: "党员编号",
          key: "userCode",
          width: 120,
          align: "center",
        },
        {
          title: "姓名",
          key: "name",
          width: 120,
          align: "center",
        },
        {
          title: "联系电话",
          key: "account",
          width: 140,
          align: "center",
        },
        {
          title: "所属社区",
          key: "community",
          width: 150,
          align: "center",
        },
        {
          title: "活动报名次数",
          key: "serviceTime",
          width: 140,
          align: "center",
        },
        {
          title: "取消报名次数",
          key: "joinService",
          width: 140,
          align: "center",
        },
        {
          title: "实际参加次数",
          key: "joinDate",
          width: 140,
          align: "center",
        },
        {
          title: "提交活动记录数",
          key: "joinDate",
          align: "center",
        },
        {
          title: "排名",
          key: "joinDate",
          width: 120,
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          width: 100,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small",
                  },

                  on: {
                    click: () => {
                      this.activeParty(params.row);
                    },
                  },
                },
                "活动记录"
              ),
            ]);
          },
        },
      ],
       partySelectList: [
        {
          label: "编号",
          type: "text",
          value: "",
        },
        {
          label: "姓名",
          type: "text",
          value: "",
        },
        {
          label: "电话号码",
          type: "text",
          value: "",
        },
        {
          label: "社区",
          type: "text",
          value: "",
        },
        {
          label: "排名",
          type: "select",
          value: "",
          list: [
            {
              name: "按报名次数排",
              value: "1",
            },
            {
              name: "按取消次数排",
              value: "2",
            },
            {
              name: "按实际参与排",
              value: "3",
            },
            {
              name: "按提交活动记录排",
              value: "4",
            },
          ],
        },
      ],
      // 分页器总数量
      total: 0,
      //分页器当前页
      curPage: 1,
      pageSize: 20,
    };
  },
  methods: {
     //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "党员编号",
            "姓名",
            "联系电话",
            "所属社区",
            "活动报名次数",
            "取消报名次数",
            "实际参加次数",
            "提交活动记录数",
            "排名"
          ];
          let filterVal = [
            "userCode",
            "name",
            "account",
            "community",
            "serviceTime",
            "joinService",
            "joinDate",
            "joinDate",
            "joinDate"
          ];
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "党员活动记录情况统计列表"
          );
        },
      });
    },
     search() {
      let obj = {
        userCode: this.partySelectList[0].value,
        name: this.partySelectList[1].value,
        account: this.partySelectList[2].value,
        skill: this.partySelectList[3].value,
        joinService: this.partySelectList[4].value,
        page: 1,
        pageSize: this.pageSize,
      };
    },
    //复选框
    selectTable() {},
    //分页器页数改变
    pageChange() {},
    //操作中的活动记录
    activeParty(data) {},
  },
  created() {
    //获取职业分类列表
    this.$get("/datamsg/api/common/sys/findDictByDictType", {
      dictType: "COMMUNIST_SKILL",
    }).then((res) => {
      if (res.code == 200) {
        let result = JSON.parse(
          JSON.stringify(res.dataList)
            .replace(/dictKey/g, "value")
            .replace(/dictValue/g, "name")
        );
        this.partySelectList.map((item, index) => {
          if (item.label == "职业分类：") {
            this.partySelectList[index].list = result;
          }
        });
      }
    });
  },
  components: {
    LiefengContent,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
#toolsbarRight {
  flex: 2;
  .ivu-form {
    height: 32px;
    display: flex;
    text-align: left;

    .ivu-form-item {
      display: flex;
      align-items: center;
      width: 180px;
      margin-right: 20px;
      margin-bottom: 0;

      /deep/.ivu-form-item-label {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        line-height: 30px;
        color: #616467;
        padding-right: 0px;
        margin-right: 10px;
      }
      /deep/.ivu-select {
        flex: 1;
      }
      /deep/.ivu-form-item-content {
        flex: 1;
      }

    }
}
  }
</style>